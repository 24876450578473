import { Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box } from '@mui/material/';
import { ToolContext } from '../core/context/ToolContext';
import { WebRoute } from "../core/webrouter/Administrator";
import { SnakeLoader } from '../core/loader/SnakeLoader'
import { Logo } from '../core/global/Icons';
import { InstallPromotion } from './components/layouts/installation/InstallPromotion';
import { rqx } from '../core/request/API';
import { env } from '../core/Env';
// import { motion } from 'framer-motion';

export const Core = () => {
	const { tool_state, install_state } = useContext(ToolContext)
	const { __SESSION } = tool_state

	const installPromptHandler = () => {
		if (install_state.prompt.data === null) return
		setTimeout(() => {
			install_state.ip.set({stat: true, prompt_type: 'first_prompt'})
		}, 2000);
	}
	
	useEffect(() => {
		const __init = async () => {
			// RUN AN API REQUEST HERE ( SAMPLE REQUESTS BELOW: )
			let pinfo = await rqx.get(`<<tool>>/${env()}/account/primeinfo/read`, {aid: __SESSION.data.ainfo.aid})
			__SESSION.set({...__SESSION.data, pinfo: pinfo})
			tool_state.prefetch.set(true)
		}
		
		if (__SESSION.data.auth) {
			installPromptHandler()
			__init()
		} else {
			setTimeout(() => {
				// SET STATES HERE ( SAMPLE STORING BELOW: )
				tool_state.prefetch.set(true)

				// RUN PWA INSTALLATION PROMPT HANDLER
				installPromptHandler()
			}, 1000);
		}
		
		console.log(`POFYourtool V2.0.4 is running`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Box width="100%" height="100%">
			{
				tool_state.prefetch.data ? (
					<Router>
						<Suspense fallback={
							<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
								<Logo size={60}/>
								<Box mt={4}>
									<SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
								</Box>
							</Box>
						}>
							<WebRoute />
						</Suspense>
					</Router>
				) : (
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<Logo size={60}/>
						<Box mt={4}>
							<SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
						</Box>
					</Box>
				)
			}
			<InstallPromotion install_state={install_state} />
		</Box>
	)
}