import { Skeleton } from '@mui/material';
import { Line } from 'react-chartjs-2';

export const Chart = ({ load, tdetails }) => {
    return load === 'loader' ? <Loader /> : <Data tdetails={tdetails} />
}

const Loader = () => {
    return (
        <Skeleton variant="rect" width="100%" height={167.5} sx={{borderRadius: 4, mr: 2, backgroundColor: '#bababa'}} />
    )
}

const Data = ({ tdetails }) => {
    return (
        <Line data={{ 
                labels: tdetails.data.analytical.graph.labels,
                datasets: tdetails.data.analytical.graph.datasets,
            }}
            options={{
                plugins: {
                    legend: {
                        display: false
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks:{
                            display: false,
                            stepSize: 5
                        },
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawBorder: false,
                        }
                    },
                    x: {
                        // ticks:{display: false},
                        grid:{
                            display: false,
                            drawBorder: false,
                        }
                    }
                }
            }}
        />
    )
}