import { useContext } from 'react';
import { Box, Drawer} from '@mui/material';
import { ArrowLeftCircle1 as IArrowLeftCircle1 } from '../../../../core/global/Icons';
import { makeStyles } from '@mui/styles';
import { ToolContext } from '../../../../core/context/ToolContext';

export const TC = () => {
    const { navigation_state } = useContext(ToolContext)
    const { tc } = navigation_state
    
    const width = 274
    const useStyles = makeStyles((theme) => ({
        drawer_paper: {
            marginLeft: 80,
            width: tc.data.open ? width : 0,
        },
        drawerToggle: {
            width: tc.data.open ? width : 0,
            overflowX: "hidden",
            whiteSpace: 'nowrap',
            border: '0px solid white',
            zIndex: 1201,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration[tc.data.open ? 'leavingScreen' : 'enteringScreen'],
            }),
            [theme.breakpoints.down(1650)]: {
                position: 'fixed',
                backgroundColor: 'pink'
            }
            
        }
    }));
    const classes = useStyles();

    return (
        // <Drawer anchor="left" open={tc.data.open} className={`${classes.drawerToggle} shadow`} classes={{paper: `${classes.drawer_paper} `}} ModalProps={{hideBackdrop: true}}>
        <Drawer anchor="left" variant="permanent" className={`${classes.drawerToggle} shadow`} classes={{paper: `${classes.drawerToggle} ${classes.drawer_paper} `}} ModalProps={{hideBackdrop: true}}>
            <Box display="flex" flexDirection="column" height="100%" bgcolor="#fff" mt={7}>
                <Box px={2.5} display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                    <Box component="b"> Tool Categories</Box>
                    <Box onClick={()=>tc.set({...tc.data, list: !tc.data.list})} display="flex"><IArrowLeftCircle1 stroke="#babdc9" /></Box>
                </Box>
            </Box>
        </Drawer>
    );
}
