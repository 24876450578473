import { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { Close as IClose } from '@mui/icons-material';

export const TCDrawer = () => {
    const { navigation_state } = useContext(ToolContext)
    const { tc } = navigation_state
    
    const height = 100
    const useStyles = makeStyles((theme) => ({
        container: {
            position: 'absolute', 
            bottom: '0', 
            left: '0', 
            width: `100%`,
            height: tc.data.list ? `${height}%` : '0%',
            transition: theme.transitions.create('height', {
                easing: theme.transitions.easing.sharp,
                // duration: theme.transitions.duration[tc.data.open ? 'leavingScreen' : 'enteringScreen'],
                duration: theme.transitions.duration.standard
            }),
            [theme.breakpoints.down(1650)]: {
                paddingLeft: 274, 
            }
        },
        tc_drawer: {
            backgroundColor: 'rgba(0,0,0,0.3)',
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            width: `100%`,
            height: `100%`
        },
    }));
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Box className={classes.tc_drawer}>
                <Box p="24px 24px 0 24px" height="100%">
                    <Box p={2} bgcolor="#fff" borderRadius="16px 16px 0 0" height="100%">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box></Box>
                            <Box component={Button} minWidth="auto" onClick={()=>tc.set({...tc.data, list: false})}><IClose /></Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
