import { Box, Grid, Button, Divider, Skeleton } from "@mui/material"
import { MoreVert as IMoreVert } from "@mui/icons-material"
import { avatarstylesx } from "../../../../../../core/styling/global"
import PSLLogo1 from "../../../../../../assets/images/logos/logo_v1.png"
import INoList from "../../../../../../assets/images/icons/no_list.png"
import moment from 'moment'
import Currency from 'react-currency-format';

export const Recent = ({ load, tdetails }) => {
    return load === 'loader' ? <Loader /> : <Data tdetails={tdetails} />
}

const Loader = () => {
    return (
        <Box mt={7}>
            <Skeleton variant="rect" width={158} height={21.25} sx={{borderRadius: 4, mb: 3, backgroundColor: '#bababa'}} />
            <Grid container spacing={3} sx={{fontSize: 12, color: 'gray', px: '12px'}}>
                <Grid item xs={3}><Skeleton variant="rect" width={65} height={17} sx={{borderRadius: 4, backgroundColor: '#bababa'}} /></Grid>
                <Grid item xs={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}><Skeleton variant="rect" width={31} height={17} sx={{borderRadius: 4, backgroundColor: '#bababa'}} /></Grid>
                        <Grid item xs={3}><Skeleton variant="rect" width={85} height={17} sx={{borderRadius: 4, backgroundColor: '#bababa'}} /></Grid>
                        <Grid item xs={3}><Skeleton variant="rect" width={54} height={17} sx={{borderRadius: 4, backgroundColor: '#bababa'}} /></Grid>
                        <Grid item xs={2}><Skeleton variant="rect" width={46} height={17} sx={{borderRadius: 4, backgroundColor: '#bababa'}} /></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={2}>
                <Skeleton variant="rect" width="100%" height={64.2} sx={{borderRadius: 4, backgroundColor: '#bababa'}} />
                <Divider sx={{my: 1, px: 1.5}} />
                <Skeleton variant="rect" width="100%" height={64.2} sx={{borderRadius: 4, backgroundColor: '#bababa'}} />
                <Divider sx={{my: 1, px: 1.5}} />
                <Skeleton variant="rect" width="100%" height={64.2} sx={{borderRadius: 4, backgroundColor: '#bababa'}} />
            </Box>
        </Box>
    )
}

const Data = ({ tdetails }) => {
    return (
        <Box mt={7}>
            <Box color="primary.main" mb={3}><b>Recent subscribers</b></Box>
            <Box>
                <Grid container spacing={3} sx={{fontSize: 12, color: 'gray', px: '12px'}}>
                    <Grid item xs={3}><b>HERO USER</b></Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}><b>PLAN</b></Grid>
                            <Grid item xs={3}><b>DATE AVAILED</b></Grid>
                            <Grid item xs={3}><b>AMOUNT</b></Grid>
                            <Grid item xs={2}><b>STATUS</b></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                </Grid>
                <Box mt={2}>
                    {
                        tdetails.data.analytical.users.length > 0 ? (
                            tdetails.data.analytical.users.map((u, k) => {
                                let display = JSON.parse(u.display)
                                display = display !== null ? display.profile !== null ? display.profile !== null ? display.profile : PSLLogo1 : PSLLogo1 : PSLLogo1
                                return (
                                    <Box key={k}>
                                        <Box component={Button} justifyContent="left" fullWidth p={1.5} borderRadius={3} className="c-pointer">
                                            <Grid container spacing={3} sx={{alignItems: 'center', fontSize: 12, color: '#003539'}}>
                                                <Grid item xs={3}>
                                                    <Box textAlign="left" display="flex" alignItems="center">
                                                        <Box sx={avatarstylesx(display, 40, 40)} />
                                                        <Box ml={1} mt={0.8}>
                                                            <Box whiteSpace="nowrap">
                                                                <Box display="flex" component="b">
                                                                    <Box textOverflow="ellipsis" overflow="hidden">{u.first_name !== null ? `${u.first_name} ${u.middle_name} ${u.last_name}` : 'Unnamed Hero'}</Box>
                                                                </Box>
                                                                <Box display="flex" fontSize={10} mt={-0.8}>
                                                                    <Box textOverflow="ellipsis" overflow="hidden">{u.email}</Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Grid container spacing={3} sx={{alignItems: 'center'}}>
                                                        <Grid item xs={4} sx={{textAlign: 'left'}}>
                                                            <Box><b>{u.plan_name}</b></Box>
                                                            <Box fontSize={11}>{u.interval_name}</Box>
                                                        </Grid>
                                                        <Grid item xs={3} sx={{textAlign: 'left'}}>
                                                            <b>{moment(u.date_availed).format('MMMM DD, YYYY')}</b>
                                                        </Grid>
                                                        <Grid item xs={3} sx={{textAlign: 'left'}}><b>₱<Currency value={u.amount} displayType={'text'} thousandSeparator={true} prefix={''} /></b></Grid>
                                                        <Grid item xs={2} sx={{textAlign: 'left'}}><b>{u.status === 2 ? 'PAID' : 'PENDING'}</b></Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IMoreVert />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider sx={{my: 1, px: 1.5}} />
                                    </Box>
                                )
                            })
                        ) : (
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height={250}>
                                <img src={INoList} alt={INoList} />
                                <Box mt={1} component="b" fontSize={16} color="#04949F">No data found.</Box>
                                <Box fontSize={14}>No today's subscribers yet</Box>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </Box>
    )
}