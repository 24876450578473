import { createContext } from 'react';
import { InstallState } from './InstallState';
import { ToolStates } from './ToolStates';
import { Contexts as AuthenticationContext } from './authentication/Contexts'
import { Contexts as AdministratorContext} from './administrator/Contexts'

export const ToolContext = createContext();

export const ToolProvider = ({ children, platform }) => {
    
    let states = {
        default: {
            install_state: InstallState(),
            tool_state: ToolStates(),
            vmScreen: (window.screen.width),
            reload_state: false
        },
        authentication: {...AuthenticationContext()},
        administrator: {...AdministratorContext()}
    }
    
    return (
        <ToolContext.Provider value={{...states.default, ...states[platform]}}>
            {children}
        </ToolContext.Provider>
    )
}