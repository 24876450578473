import { Box, Grid, Button, Skeleton } from "@mui/material"
import { avatarstylesx } from "../../../../../core/styling/global"

export const List = ({ load, today, tdetails, __subs }) => {
    const selectHandler = (tool, k) => {
        let t = {...today.data}
        let td = {...tdetails.data}

        t.selected = tool
        __subs(tool.tid)

        td.analytical.sub.load = 'loader'
        td.analytical.graph.load = 'loader'
        td.analytical.users = null

        today.set(t)
        tdetails.set(td)
    }

    return load==='loader' ? <Loader /> : <Data today={today} tdetails={tdetails} selectHandler={selectHandler} />
}

const Loader = () => {
    return (
        <Grid item xs={4}>
            <Skeleton variant="rect" width="100%" height={73.95} sx={{borderRadius: 4, mr: 2, backgroundColor: '#bababa'}} />
        </Grid>
    )
}

const Data = ({ today, tdetails, selectHandler }) => {
    return (
        <Grid item xs={4}>
            {
                today.data.values.subs.tools.map((t, k) => (
                    <Box component={Button} disabled={tdetails.data.analytical.users === null} justifyContent="left" fullWidth bgcolor={today.data.selected.tid === t.tid ? '#c8dede' : '#fff'} p={2} borderRadius={3} mb={2} className="c-pointer" key={k} onClick={()=>selectHandler(t, k)}>
                        <Grid container spacing={3} sx={{alignItems: 'center'}}>
                            <Grid item xs={1}>
                                <Box component="b" fontSize={18}>{k+1}</Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Box textAlign="left" display="flex" alignItems="center">
                                    <Box sx={avatarstylesx(JSON.parse(t.logo), 40, 40)} />
                                    <Box ml={1} mt={0.8}>
                                        <Box fontSize={10} mb={-0.8}>{t.abbreviation}</Box>
                                        <Box component="b">{t.name}</Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box fontSize={18} textAlign="right" color="secondary.main"><b>{t.total_subs}</b></Box>
                            </Grid>
                        </Grid>
                    </Box>
                ))
            }
        </Grid>
    )
}
