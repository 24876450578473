import { useState } from "react"
import { Home1, Notification1 } from '../../global/Icons';

export const NavigationStates = () => {
    const [tabs, setTabs] = useState({
        top: [
            {name:'Notifications', subdir:'__notif', ref: '__notif', not_active:<Notification1 />, active:<Notification1 fill="#fff" stroke="#FF7913"/>}
        ],
        left: [
            {name:'Dashboard', subdir:'/', ref: '', not_active:<Home1 stroke="#537477"/>, active:<Home1 fill="#537477" stroke="#fff"/>},
        ]
    })
    const [tc, setTc] = useState({open: false, list: false})

    return {
        tabs: {data: tabs, set: setTabs},
        tc: {data: tc, set: setTc}
    }
}