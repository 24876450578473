import { Box, Grid } from "@mui/material"

export const TActiveUsers = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Box p={2} borderRadius={3} bgcolor="#fff">

                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box p={2} borderRadius={3} bgcolor="#fff">

                </Box>
            </Grid>
        </Grid>
    )
}