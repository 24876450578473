import { Route, Switch } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { SI } from '../../__authentication/components/si/Index';

export const WebRoute = ({ platform }) => {
    return (
        <Switch>
            {/* SI ROUTES */}
            <Route exact path={'/'} render={props=>(<SI {...props} />)} />
            
            {/* 404 NOT FOUND */}
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}
