import { useState } from "react"

export const ToolStates = () => {
    const [prefetch, setPrefetch] = useState(false)
    const [__SESSION, setSession] = useState({auth: null, ainfo: null, pinfo: null})

    return {
        prefetch: {data: prefetch, set: setPrefetch},
        __SESSION: {data: __SESSION, set: setSession}
    }
}