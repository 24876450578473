import { useEffect } from "react"
import { List } from "./List"
import { Box, Grid } from "@mui/material"
import { Chart } from "./Chart"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { Stat } from "./subs/Stat"
import { Recent } from "./subs/Recent"
import INoList from "../../../../../assets/images/icons/no_list.png"
import moment from 'moment'


export const TSubscibers = ({ today, tdetails }) => {
    const animations = {
        y: {
            duration: 1500,
            easing: 'easeInOutElastic'
        },
        tension: {
            duration: 2000,
            easing: 'easeInOutElastic',
            from: 0.4,
            to: 0.4,
            loop: true
        }
    }

    useEffect(() => {
        const __init = async () => {
            const tools = await rqx.get(`<<tool>>/${env()}/report/mat/cday`, {cday: JSON.stringify([moment().format('MM-DD-YYYY [00:00:01]'), moment().format('MM-DD-YYYY [23:23:59]')]), ref: '!all'})
            let t = {...today.data}
            t.values.subs.tools = tools
            if (tools.length > 0) {
                if (t.selected === null) {
                    t.selected = tools[0]
                    __subs(tools[0].tid)
                }
            } else {
                t.selected = 'none'
            }
            
            today.set(t)
        }

        __init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const __subs = async (tid) => {
        const ft = await rqx.get(`<<tool>>/${env()}/report/mat/subs`, {tid, pm: 'FREE_TRIAL', cday: JSON.stringify([moment().format('MM-DD-YYYY [00:00:01]'), moment().format('MM-DD-YYYY [23:23:59]')]), ref: '!all'})
        const fa = await rqx.get(`<<tool>>/${env()}/report/mat/subs`, {tid, pm: 'FREE_ACCESS', cday: JSON.stringify([moment().format('MM-DD-YYYY [00:00:01]'), moment().format('MM-DD-YYYY [23:23:59]')]), ref: '!all'})
        const wp = await rqx.get(`<<tool>>/${env()}/report/mat/subs`, {tid, pm: 'WITH_PAYMENT', cday: JSON.stringify([moment().format('MM-DD-YYYY [00:00:01]'), moment().format('MM-DD-YYYY [23:23:59]')]), ref: '!all'})
        let td = tdetails.data
        td.analytical.sub.load = 'data'
        td.analytical.users = null
        let vals = [ft, fa, wp]
        vals.sort(function(a, b) {
            return b.count - a.count;
        }).map((v, k)=>{
            if (k===0) return {...v, pallete: '#4caf50'}
            if (k===1) return {...v, pallete: '#7aa3a1'}
            if (k===2) return {...v, pallete: '#f6685e'}
            return v
        }).map((v, k) => {
            td.analytical.sub[v.pm.toLowerCase()] = {count: v.count, pallete: v.pallete}
            return v
        })

        tdetails.set(td)
        __graph(tid)
    }
    
    const __graph = async (tid) => {
        let gd = {formatted: [], raw: [], datasets: []}
        for (let i = 0; i < 8; i++) { 
            gd.raw = [[moment().subtract(i, 'months').startOf('month').format('MM-DD-YYYY [00:00:01]'), moment().subtract(i, 'months').endOf('month').format('MM-DD-YYYY 23:59:59')], ...gd.raw]
            gd.formatted = [moment().subtract(i, 'months').startOf('month').format('MMM YYYY'), ...gd.formatted]
        }

        const subscribed = await rqx.get(`<<tool>>/${env()}/report/subscriber/graph`, {tid, gd: JSON.stringify(gd.raw), ref: 'subscribed'})
        gd.datasets.push({label: 'Subscribers', animations: animations, data: subscribed.gd, backgroundColor: ['#007c74'], borderColor: ['#007c74'] })
        const unsubscribed = await rqx.get(`<<tool>>/${env()}/report/subscriber/graph`, {tid, gd: JSON.stringify(gd.raw), ref: 'unsubscribed'})
        gd.datasets.push({label: 'Unsubscribers', animations: animations, data: unsubscribed.gd, backgroundColor: ['#f6685e'], borderColor: ['#f6685e'] })
        
        tdetails.set({...tdetails.data, analytical: {...tdetails.data.analytical, graph: {load: 'data', labels: gd.formatted, datasets: gd.datasets}}})
        __users(tid, {load: 'data', labels: gd.formatted, datasets: gd.datasets} )
    }

    const __users = async (tid, graph) => {
        const users = await rqx.get(`<<tool>>/${env()}/report/subscriber/users`, {tid, cday: JSON.stringify([moment().format('MM-DD-YYYY [00:00:01]'), moment().format('MM-DD-YYYY [23:23:59]')]), ref: '!all'})
        tdetails.set({...tdetails.data, analytical: {...tdetails.data.analytical, graph: graph, users: users}})
    }

    return (
        <Box>
            <Box fontSize={24} color="primary.main" mb={2}><b>Tools</b></Box>
            {
                today.data.values.subs.tools !== null ? (
                    today.data.values.subs.tools.length > 0 ? (
                        <Grid container spacing={5}>
                            <List load="data" today={today} tdetails={tdetails} __subs={__subs} />
                            <Grid item xs={8}>
                                <Box display="flex" width="100%" height={300}>
                                    <Grid container spacing={5}>
                                        {
                                            tdetails.data.analytical.sub.load === 'data' ? (
                                                <Stat load="data" today={today} tdetails={tdetails} />
                                            ) : (
                                                <Stat load="loader" />
                                            )
                                        }
                                        <Grid item xs={12}>
                                            <Box width="100%" height="100%">
                                                {
                                                    tdetails.data.analytical.graph.load === 'data' ? (
                                                        <Chart load="data" tdetails={tdetails}/>
                                                    ) : (
                                                        <Chart load="loader"/>
                                                    )
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {
                                    tdetails.data.analytical.users !== null ? (
                                        <Recent load="data" tdetails={tdetails} />
                                    ) : (
                                        <Recent load="loader" />
                                    )
                                }
                            </Grid>
                        </Grid>
                    ) : (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height={250}>
                            <img src={INoList} alt={INoList} />
                            <Box mt={1} component="b" fontSize={16} color="#04949F">No data found.</Box>
                            <Box fontSize={14}>No today's subscribers yet</Box>
                        </Box>
                    )
                ) : (
                    <Grid container spacing={5}>
                    <List load="loader" />
                    <Grid item xs={8}>
                        <Box display="flex" width="100%" height={300}>
                            <Grid container spacing={5}>
                                <Stat load="loader" />
                                <Grid item xs={12}>
                                    <Chart load="loader" />
                                </Grid>
                            </Grid>
                        </Box>
                        <Recent load="loader" />
                    </Grid>
                </Grid>
                )
            }
        </Box>    
    )
}