import { useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { ToolContext } from '../../../core/context/ToolContext';
import { Wave1 as IWave1 } from '../../../core/global/Icons';
import { MAT } from './mat/Index';
import { TSubscibers } from './today/subscribers/Index';
import { homestyle } from '../../../core/styling/home';
import { MultilineChart as IMultilineChart, Dehaze as IDehaze } from '@mui/icons-material';
import { TActiveUsers } from './today/activeUsers/Index';
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import Currency from 'react-currency-format';
import moment from 'moment';

export const Home = () => {
    const { tool_state, home_state } = useContext(ToolContext)
    const { today, totals } = home_state
    const __SESSION = tool_state.__SESSION.data
    const theme = useTheme();
    const XLScreen = useMediaQuery(theme.breakpoints.up(1921))
    const classes = homestyle()
    
    useEffect(() => {
        const __init = async () => {
            const r = await rqx.get(`<<tool>>/${env()}/report/revenue/read`, {ref: 'all'})
            const s = await rqx.get(`<<tool>>/${env()}/report/subscriber/read`, {ref: 'all'})
            const uv = await rqx.get(`<<tool>>/${env()}/report/user/read`, {ref: 'active'})
            const uuv = await rqx.get(`<<tool>>/${env()}/report/user/read`, {ref: 'inactive'})
            
            const ts = await rqx.get(`<<tool>>/${env()}/report/subscriber/read`, {ref: 'today', cday: JSON.stringify([moment().format('MM-DD-YYYY [00:00:01]'), moment().format('MM-DD-YYYY [23:23:59]')])})
            totals.set({...totals.data, revenue: r.total, subscribers: s.total, users: {verified: uv.total, unverified: uuv.total}, today: {subs: ts.total, active: 0}})
        }

        __init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <Box display="flex" width="100%" height="100%">
            <Box width="100%" height="100%" p={3} pr={3} display="flex" flexDirection="column" alignItems="center" sx={{overflowY: 'auto'}}>
                <Box width={XLScreen?1280:'100%'}>
                    <Box component="b" fontSize={20} color="primary.main">Dashboard</Box>
                    <Box display="flex" mt={2.5}>
                        Welcome back 
                        <Box pl={0.5} component="b" mr={2}> { __SESSION.pinfo.length > 0 ? `${__SESSION.pinfo[0].first_name} ${__SESSION.pinfo[0].last_name}!` : `${__SESSION.ainfo[__SESSION.ainfo.email!==null?'email':'mobile_num']}!`}</Box>
                        <IWave1 fill="#ffcb22" stroke="none" />
                    </Box>
                    <Grid container spacing={5} sx={{mt: 3}}>
                        <Grid item xs={4}>
                            <Box bgcolor="secondary.light" borderRadius={2} p={2} className="shadow" height="100%">
                                <Box fontSize={14} component="b">Total Revenue</Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                    {/* <img src={IFList1} alt={IFList1} /> */}
                                    <Box fontSize={35} color="primary.main" sx={{cursor: 'default'}} component="b">₱<Currency value={totals.data.revenue} displayType={'text'} thousandSeparator={true} prefix={''} /></Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box bgcolor="secondary.light" borderRadius={2} p={2} className="shadow" height="100%">
                                <Box fontSize={14} component="b">Total Subscribers</Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                    {/* <img src={IFList1} alt={IFList1} /> */}
                                    <Box fontSize={35} color="primary.main" sx={{cursor: 'default'}} component="b">{<Currency value={totals.data.subscribers} displayType={'text'} thousandSeparator={true} prefix={''} />}</Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box bgcolor="secondary.light" borderRadius={2} p={2} className="shadow" height="100%">
                                <Box fontSize={14} component="b">Total Users</Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                    {/* <img src={IFList1} alt={IFList1} /> */}
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Box fontSize={13}>Verified</Box>
                                            <Box mt={-1} fontSize={30} color="primary.main" sx={{cursor: 'default'}}><b><Currency value={totals.data.users.verified} displayType={'text'} thousandSeparator={true} prefix={''} /></b></Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box fontSize={13}>Unverified</Box>
                                            <Box mt={-1} fontSize={30} color="primary.main" sx={{cursor: 'default'}}><b><Currency value={totals.data.users.unverified} displayType={'text'} thousandSeparator={true} prefix={''} /></b></Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box mt={6}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" width="100%">
                                <Box width={today.data.viewing === 'analytical' ? 250 : '50%'} className={`${classes.tab_transitions}`}>
                                    <Box component={Button} borderRadius={3} p={1.5} justifyContent="space-between" className={`${today.data.active === 'subs' ? classes.active_btn : classes.inactive_btn} ${classes.tab_transitions}`}>
                                        <Box ml={1}>Today's subscribers</Box>
                                        <Box color="#fff" ml={5} px={1} borderRadius={2} fontSize={12} className={today.data.active === 'subs' ? classes.active_count : classes.inactive_count}>{totals.data.today.subs}</Box>
                                    </Box>
                                </Box>
                                <Box component={Button} ml={today.data.viewing === 'analytical' ? 3 : 6.5} borderRadius={3} p={1.5} justifyContent="space-between" className={`${today.data.active === 'active' ? classes.active_btn : classes.inactive_btn}`}>
                                    <Box ml={1}>Today's active users</Box>
                                    <Box color="#fff" ml={5} px={1} borderRadius={2} fontSize={12} className={`${today.data.active === 'active' ? classes.active_count : classes.inactive_count}`}>{totals.data.today.active}</Box>
                                </Box>
                                <Box />
                            </Box>
                            <Box display="flex" alignItems="center" bgcolor="#fff" borderRadius={2} p={0.5} sx={{cursor: 'pointer'}}>
                                <Box display="flex" p={1} borderRadius={2} className={`${today.data.viewing === 'analytical' ? classes.active_btn : ''}`} onClick={()=>today.set({...today.data, viewing: 'analytical'})}><IMultilineChart sx={{fontSize: 20}}/></Box>
                                <Box display="flex" p={1} borderRadius={2} className={`${today.data.viewing === 'simplified' ? classes.active_btn : ''}`} onClick={()=>today.set({...today.data, viewing: 'simplified'})}><IDehaze sx={{fontSize: 20}}/></Box>
                            </Box>
                        </Box>
                        <Box borderRadius={3} mt={3}>
                            {
                                today.data.viewing === 'analytical' ? (
                                    <TSubscibers classes={classes} {...home_state} />
                                ) : (
                                    <TActiveUsers classes={classes} {...home_state} />
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            <MAT XLScreen={XLScreen} classes={classes} {...home_state} />
        </Box>
    )
}
