import { lazy, Suspense, useContext, useEffect } from "react"
import { VM } from "../../../core/VM"
import { pslstyle } from "../../../core/styling/si"
import { ToolContext } from "../../../core/context/ToolContext";
import { validateAccount } from "../../../core/validator/Validator";
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import { useLocation, useHistory } from 'react-router-dom';

const device = VM();
const Auth = lazy(() => import(`.${device}/Auth`))
const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))

export const SI = ({ match }) => {
    const { si_state } = useContext(ToolContext)
    const { step, va, inputs, tpinputs } = si_state
    const q = new URLSearchParams(useLocation().search);
    const platform = match.params.platform
    const classes = pslstyle(platform)
    const history = useHistory()

    useEffect(() => {
        step.set(1)
        va.set('')
        inputs.set({account:{value: '', stat: false, msg: ''}, password:{value: '', stat: false, msg: '', toggle: false}})
        tpinputs.set({google: {stat: false, msg: ""}, fb: {stat: false, msg: ""}})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const inputHandler = (e) => {
        const {name, value} = e.target
        tpinputs.set({google: {stat: false, msg: ""}, fb: {stat: false, msg: ""}})
        inputs.set({...inputs.data, [name] : {...inputs.data[name], value: value, stat: false, msg: ''}})
        if (name==='account') {
            const v = validateAccount(e.target.value);
            if (v==='mobile_num' && e.target.value[0]==='0') {
                inputs.set({...inputs.data, [name] : {...inputs.data[name], value: e.target.value.substring(1), stat: false, msg: ''}})
            }
            va.set(v)
        }
    }

    const resetPassHandler = () => {
        // va = validated account, rrf = reset request from
        const sainfo = {
            va: va.data,
            default: window.btoa(`unauth_${inputs.data.account.value}`),
            rrf: platform,
            paid: null
        }

        history.push(`/${platform}/reset/password?sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`)
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? next() : ''}
    const next = async () => {
        if (step.data!==10 && step.data!==20) {
            tpinputs.set({google: {stat: false, msg: ""}, fb: {stat: false, msg: ""}})
            inputs.set({password: {...inputs.data.password, stat: false, msg: ''}, account: {...inputs.data.account, stat: false, msg: ''}})
            if (step.data===1) {
                step.set(10)
                if (inputs.data.account.value !== '') {
                    let attempt = await rqx.get(`<<tool>>/${env()}/auth/si/attempt`, {account: inputs.data.account.value, ref: 1})
                    if (attempt.msg === 'valid') {
                        step.set(2)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}})
                    } else {
                        step.set(1)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account does not exist"}})
                    }
                } else {
                    step.set(1)
                    inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account does not exist"}})
                }
            } else {
                step.set(20)
                if (inputs.data.password.value !== '') {
                    let attempt = await rqx.get(`<<tool>>/${env()}/auth/si/attempt`, {account: inputs.data.account.value, password: inputs.data.password.value, platform, ref: 2})
                    if (attempt.msg === 'valid') {
                        inputs.set({...inputs.data, password: {...inputs.data.password, stat: false, msg: ''}})

                        window.location.href = '/'
                    } else {
                        step.set(2)
                        inputs.set({...inputs.data, password: {...inputs.data.password, stat: true, msg: "Incorrect password."}})
                    }
                } else {
                    step.set(2)
                    inputs.set({...inputs.data, password: {...inputs.data.password, stat: true, msg: "Password is required"}})
                }
            }
        }
    }
    
    return (
        <Suspense fallback="">
            <Auth classes={classes} platform={platform} {...si_state} inputHandler={inputHandler} enterHandler={enterHandler} next={next} q={q} __SESSION={__SESSION} resetPassHandler={resetPassHandler} />
        </Suspense>
    )
}