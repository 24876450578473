import { Home } from '../../__administrator/components/home/Index';
import { Route, Switch } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { VM } from '../VM';
import { lazy } from 'react';
import { TC } from '../../__administrator/components/layouts/tc/Index';
import { TCDrawer } from '../../__administrator/components/layouts/tc/drawer/Index';

let view = VM();
const Navigation = lazy(() => import(`../../__administrator/components/layouts/navigations${view}/Index`))

export const WebRoute = () => {
    return (
        <Switch>
            {/* HOME ROUTES */}
            <Route exact path="/" render={props=>(Render(Home, props))} />

            {/* 404 NOT FOUND */}
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}

const Render = (Component, props) => {
    return (
        <Box width="100%" height="100%">
            <Navigation side={0} />
            <Box display="flex" width="100%" height="100%">
                <Navigation side={1} />
                <TC />
                <Box component="main" flex={1} mt={7}>
                    <Box sx={{position: 'relative'}} width="100%" height="100%">
                        <Box width="100%" height="100%"><Component {...props} /></Box>
                        <TCDrawer />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
