import { makeStyles } from '@mui/styles';

export const homestyle = (pmstat = false) => {
    let s = makeStyles(theme => ({
        search_tf: {
            fontSize: '10px',
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                '&.Mui-focused': {
                    width: '150%',
                    borderColor: theme.palette.primary.main,
                },
            }
        },
        tf: {
            fontSize: '10px',
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                },
            }
        },
        btn: {
            '&:hover': {
                backgroundColor: '#e8ebeb',
            }
        },
        btn_2: {
            '&:hover': {
                backgroundColor: '#c2c2c2',
            }
        },
        btn_pm_active: {
            backgroundColor: theme.palette.secondary.main,
            color: '#fff',
            '&:hover': {
                backgroundColor: '#db762a',
            }
        },
        btn_pm_inactive: {
            backgroundColor: '#fff',
            color: theme.palette.primary.tf,
            '&:hover': {
                backgroundColor: '#c2c2c2',
            }
        },
        inactive_btn: {
            backgroundColor: '#ffffff'
        },
        inactive_count: {
            backgroundColor: '#808080'
        },
        active_btn: {
            backgroundColor: '#dbe8e9'
        },
        active_count: {
            backgroundColor: '#003539'
        },
        active_list: {
            backgroundColor: '#A4B7B8'
        },
        tab_transitions: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.standard,
            }),
        }
    }))

    return s()
}