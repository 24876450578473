import { env } from "../../core/Env"
import { rqx } from "../../core/request/API"

const authentication = async () => {
    return await rqx.get(`<<tool>>/${env()}/auth/si/verify`, '')
}

const authorization = async () => {
    
}

export const verify = {authentication, authorization}