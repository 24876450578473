import { Box, Button, Divider, Grid } from '@mui/material'
import { prominent } from 'color.js'
import { useEffect } from 'react'
import { avatarstylesx } from '../../../../core/styling/global'
import { Charts } from './Chart'
import { ArrowDownward as IArrowDownward, ArrowUpward as IArrowUpward, ArrowBackIos as IArrowBackIos, ArrowForwardIos as IArrowForwardIos, PeopleOutline as IPeopleOutline, EmojiEventsOutlined as IEmojiEvents } from '@mui/icons-material'
import { rqx } from '../../../../core/request/API'
import { env } from '../../../../core/Env'
import LimitText from "react-show-more-text"
import moment from 'moment'

// MAT = Most Availed Tools
export const MAT = ({ mat, matStat, fetching, XLScreen }) => {
    useEffect(() => {
        const __init = async () => {
            const tools = await rqx.get(`<<tool>>/${env()}/report/mat/read`, {})
            mat.set({...mat.data, tools: tools})       
            __set(tools, 0)
        }
        
        __init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const __set = (t, i) => {
        prominent(JSON.parse(t[i].logo), { amount: 4 }).then(colors => {
            let dominantPalletes = []
            colors.map(c => {
                dominantPalletes = [...dominantPalletes, `rgb(${c.join(', ')}, 0.5)`]
                return c
            })
            mat.set({...mat.data, viewing: i, tools: t, pallete: `linear-gradient(150deg, ${dominantPalletes[0]} 0%, ${dominantPalletes[1]} 50%, ${dominantPalletes[2]} 100%)`})
            __stat(t[i].tid)
        }).catch(error => {
            mat.set({...mat.data, viewing: i, tools: t, pallete: `#fff`})
            __stat(t[i].tid)
        })
    }

    const __stat = async (tid) => {
        let gd = {formatted: [], raw: []}
        let cweekd = [moment().startOf('month').format('MM-DD-YYYY [00:00:01]'), moment().endOf('month').format('MM-DD-YYYY [23:23:59]')]
        let pweekd = [moment().subtract(1, 'months').startOf('month').format('MM-DD-YYYY [00:00:01]'), moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY [23:23:59]')]
        for (let i = 0; i < 8; i++) { 
            gd.raw = [[moment().subtract(i, 'months').startOf('month').format('MM-DD-YYYY [00:00:01]'), moment().subtract(i, 'months').endOf('month').format('MM-DD-YYYY 23:59:59')], ...gd.raw]
            gd.formatted = [moment().subtract(i, 'months').startOf('month').format('MMMM YYYY'), ...gd.formatted]
        }
        const ms = await rqx.get(`<<tool>>/${env()}/report/mat/stat`, {tid, gd: JSON.stringify(gd.raw), cweekd: JSON.stringify(cweekd), pweekd: JSON.stringify(pweekd)})
        matStat.set({...matStat.data, current: ms.cweekd, previous: ms.pweekd, graph: {labels: gd.formatted, data: ms.gd}})
        fetching.set(false)
    }

    const moveHandler = (toggled) => {
        fetching.set(true)
        matStat.set({current: 0, previous: 0, graph: {labels: [''], data: [0]}})
        if (toggled === 'right') {
            if (mat.data.tools.length === mat.data.viewing+1) return
            __set(mat.data.tools, mat.data.viewing+1)
        } else {
            if (mat.data.viewing === 0) return
            __set(mat.data.tools, mat.data.viewing-1)
        }
    }
    

    return (
        mat.data.tools !== null ? (
            <Box height="100%" width={274} minWidth={274} bgcolor="#dbe8e9">
                <Box display="flex" flexDirection="column" p={3} m={0}>
                    <Box fontSize={14} component="b">Most availed tools</Box>
                    <Box mt={2}>
                        <Box sx={{background: mat.data.pallete}} borderRadius={3} className="shadow-sm">
                            <Box display="flex" justifyContent="space-between" px={1.5} pt={2}>
                                <Box display="flex" alignItems="center" fontSize={13} color="primary.main"><IEmojiEvents /> <b>TOP {mat.data.viewing + 1}</b></Box>
                                <Box display="flex">
                                    <Box component={Button} minWidth="" borderRadius={10} disabled={!fetching.data ? mat.data.viewing === 0 : true} onClick={() => moveHandler('left')}><IArrowBackIos sx={{fontSize: 12}} /></Box>
                                    <Box component={Button} minWidth="" borderRadius={10} disabled={!fetching.data ? mat.data.tools.length === mat.data.viewing+1 : true} onClick={() => moveHandler('right')}><IArrowForwardIos sx={{fontSize: 12}} /></Box>
                                </Box>
                            </Box>
                            <Box pt={1.5} pb={2} px={2}><Divider /></Box>
                            <Box textAlign="left" display="flex" alignItems="center" px={2}>
                                <Box sx={avatarstylesx(JSON.parse(mat.data.tools[mat.data.viewing].logo), 50, 50)} />
                                <Box ml={1} mt={0.8} width="90%">
                                    <Box fontSize={12} mb={-0.8} color="primary.main">{mat.data.tools[mat.data.viewing].abbreviation}</Box>
                                    <Box component="b" fontSize={17} color="primary.main">
                                        <LimitText lines={1} more="" less="" expanded={false} truncatedEndingComponent={"... "} className="text-justify">
                                            {mat.data.tools[mat.data.viewing].name}
                                        </LimitText>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={3} px={2}>
                                <Box fontSize={13} component="b">Total subscribers</Box>
                                <Box fontSize={11} color="primary.main" mt={-0.5}><b>As of {moment().subtract(1, 'days').format("MMMM DD, YYYY")}</b></Box> 
                                <Box display="flex" alignItems="center" mt={2}>
                                    <Box color="#fff" p={0.5} px={2.5} borderRadius={2} fontSize={16} bgcolor="primary.main">{mat.data.tools[mat.data.viewing].total_subs}</Box>
                                </Box>
                            </Box>
                            <Box width="100%" height={100} mt={4}>
                                <Charts matStat={matStat} />
                            </Box>
                        </Box>
                        <Box mt={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sx={{color: 'primary.main'}}>
                                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" bgcolor="#fff" borderRadius={3} className="shadow-sm" p={2}>
                                        <Box>
                                            <Box component="b" fontSize={28} display="flex" alignItems="center">{matStat.data.current}</Box>
                                            <Box component="b" fontSize={14} mb={1} color={matStat.data.current >= matStat.data.previous ? 'success.main' : 'error.light'}>
                                                {
                                                    matStat.data.current > matStat.data.previous ? 
                                                        <Box display="flex" alignItems="center">
                                                            <IArrowUpward sx={{fontSize: 14}} />
                                                            {matStat.data.previous !== 0 ? ((parseFloat(matStat.data.current) - parseFloat(matStat.data.previous) ) / parseFloat(matStat.data.current)) * 100 : 100}%
                                                        </Box>
                                                    : 
                                                        <Box display="flex" alignItems="center">
                                                            <IArrowDownward sx={{fontSize: 14}} />
                                                            {matStat.data.current !== 0 ? ((parseFloat(matStat.data.previous) - parseFloat(matStat.data.current)) / parseFloat(matStat.data.previous)) * 100 : 100}%
                                                        </Box>
                                                }
                                                
                                            </Box>
                                            <Box fontSize={11} mt={-0.5}>{moment().format('MMMM YYYY')} subscribers</Box>
                                        </Box>
                                        <Box display="flex" p={1} borderRadius="50%" bgcolor="#c8dede"><IPeopleOutline sx={{fontSize: 24}} /></Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box>asd</Box>
        )
    )
}
