import { Box, Grid, Skeleton } from "@mui/material"

export const Stat = ({ load, today, tdetails }) => {
    return load === 'loader' ? <Loader /> : <Data today={today} tdetails={tdetails} />
}

const Loader = () => {
    return (
        <Grid item xs={12}>
            <Box display="flex" height="100%">
                <Skeleton variant="rect" width={100} height={90.12} sx={{borderRadius: 4, mr: 2, backgroundColor: '#bababa'}} />
                <Skeleton variant="rect" width={120} height={90.12} sx={{borderRadius: 4, mr: 2, backgroundColor: '#bababa'}} />
                <Skeleton variant="rect" width={140} height={90.12} sx={{borderRadius: 4, mr: 2, backgroundColor: '#bababa'}} />
            </Box>
        </Grid>
    )
}

const Data = ({ today, tdetails }) => {
    return (
        <Grid item xs={12}>
            <Box display="flex" height="100%">
                <Box bgcolor="#fff" borderRadius={3} p={2} px={3} mr={2}>
                    <Box height="100%" display="flex" alignItems="center">
                        <Box>
                            <Box fontSize={14} color="primary.main">Free trial</Box>
                            <Box display="flex" alignItems="center">
                                <Box fontSize={24} sx={{cursor: 'default'}} color="primary.main" mr={1}><b>{tdetails.data.analytical.sub.free_trial.count}</b></Box>
                                {
                                    today.data.selected !== null && (
                                        <Box display="flex" fontSize={11}>
                                            <Box bgcolor={tdetails.data.analytical.sub.free_trial.pallete} borderRadius={4} px={1}>
                                                <b>+{((tdetails.data.analytical.sub.free_trial.count / today.data.selected.total_subs) * 100).toFixed(2)}%</b>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box bgcolor="#fff" borderRadius={3} p={2} px={3} mr={2}>
                    <Box height="100%" display="flex" alignItems="center">
                        <Box>
                            <Box fontSize={14} color="primary.main">Free access</Box>
                            <Box display="flex" alignItems="center">
                                <Box fontSize={24} sx={{cursor: 'default'}} color="primary.main" mr={1}><b>{tdetails.data.analytical.sub.free_access.count}</b></Box>
                                {
                                    today.data.selected !== null && (
                                        <Box display="flex" fontSize={11}>
                                            <Box bgcolor={tdetails.data.analytical.sub.free_access.pallete} borderRadius={4} px={1}>
                                                <b>+{((tdetails.data.analytical.sub.free_access.count / today.data.selected.total_subs) * 100).toFixed(2)}%</b>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box bgcolor="#fff" borderRadius={3} p={2} px={3} mr={2}>
                    <Box height="100%" display="flex" alignItems="center">
                        <Box>
                            <Box fontSize={14} color="primary.main">With payment</Box>
                            <Box display="flex" alignItems="center">
                                <Box fontSize={24} sx={{cursor: 'default'}} color="primary.main" mr={1}><b>{tdetails.data.analytical.sub.with_payment.count}</b></Box>
                                {
                                    today.data.selected !== null && (
                                        <Box display="flex" fontSize={11}>
                                            <Box bgcolor={tdetails.data.analytical.sub.with_payment.pallete} borderRadius={4} px={1}>
                                                <b>+{((tdetails.data.analytical.sub.with_payment.count / today.data.selected.total_subs) * 100).toFixed(2)}%</b>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}