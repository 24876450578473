import { Line } from 'react-chartjs-2';
export const Charts = ({ matStat }) => {
    return (
        <Line data={{ 
                labels: matStat.data.graph.labels,
                datasets: [
                    {
                        label: 'Subscriber/s',
                        animations: {
                            y: {
                                duration: 1500,
                                easing: 'easeInOutElastic'
                            },
                            tension: {
                                duration: 2000,
                                easing: 'easeInOutElastic',
                                from: 0.4,
                                to: 0.4,
                                loop: true
                            }
                        },
                        data: matStat.data.graph.data,
                        backgroundColor: ['#007c74'],
                        borderColor: ['#007c74'],
                        pointRadius: 2,
                    }
                ],
            }}
            options={{
                plugins: {
                    legend: {
                        display: false
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks:{
                            display: false,
                            stepSize: 5
                        },
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawBorder: false,
                        }
                    },
                    x: {
                        ticks:{display: false},
                        grid:{
                            display: false,
                            drawBorder: false,
                        }
                    }
                }
            }}
        />
    )
}