import { useState } from "react"

export const HomeStates = () => {
    // INDEX DATA
    const [totals, setTotals] = useState({revenue: 0, subscribers: 0, users: {verified: 0, unverified: 0}, today: {subs: 0, active: 0}})

    // TODAY's DATA 
    const [tanchor, setTanchor] = useState(null)
    const [today, setToday] = useState({viewing: 'analytical', active: 'subs', selected: null, values: {subs: {count: 0, tools: null}, active: {count: 0, tools: null}}})
    const [tdetails, setTdetails] = useState({
        analytical: {
            sub: {load: 'loader', free_trial: {count: 0, pallete: ''}, free_access: {count: 0, pallete: ''}, with_payment: {count: 0, pallete: ''}}, 
            graph: {load: 'loader', labels: [], datasets: []}, 
            users: null
        }, 
        simplified: {
            graph: {labels: [], data: []}, 
            users: null
        }
    })

    // STATES FOR MOST AVAILED TOOLS
    const [matStat, setMatStat] = useState({current: 0, previous: 0, graph: {labels: [''], data: [0]}})
    const [mat, setMat] = useState({pallete: '', viewing: 0, tools: null})
    const [fetching, setFetching] = useState(false)

    return {
        // INDEX stats
        totals: {data: totals, set: setTotals},

        // TODAY's states
        tanchor: {data: tanchor, set: setTanchor},
        today: {data: today, set: setToday},
        tdetails: {data: tdetails, set: setTdetails},

        // MAT states
        mat: {data: mat, set: setMat},
        matStat: {data: matStat, set: setMatStat},
        fetching: {data: fetching, set: setFetching}
    }
}