import { Core} from './Core'
import { Index as Authenticate } from '../__authentication'
import { verify } from '../__authentication/verification/Index'
import { ToolProvider, ToolContext } from "../core/context/ToolContext"
import { useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { Logo } from '../core/global/Icons'
import { SnakeLoader } from '../core/loader/SnakeLoader'

export const Index = () => {
    return <ToolProvider platform="administrator"><Data /></ToolProvider>
}

const Data = () => {
	const { tool_state, install_state } = useContext(ToolContext)
	const { __SESSION } = tool_state

	const __installer = () => {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			install_state.prompt.set(e)
		});
	}

	useEffect(() => {
		const __init = async () => {
			const v = await verify.authentication()
			if (v.msg === '!auth') return __SESSION.set({...__SESSION.data, auth: false})
			__SESSION.set({...__SESSION.data, auth: true, ainfo: v.ainfo})
		}

		__installer()
		__init()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		__SESSION.data.auth !== null ? (
			__SESSION.data.auth ? <Core /> : <Authenticate />
		) : (
			<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Logo size={60}/>
				<Box mt={4}>
					<SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
				</Box>
			</Box>
		)
	)
}